import moment from 'moment';



// Helpers
import { formatResult } from './results';



/**
 * Función que formatea la información de una orden para ser usada en el detalle de orden.
 * Tracking y OrderData
 * @param {Object} order Data de la orden que se quiera formatear
 * @returns {Object}
 */
export const formatOrder = (order) => {

   // Cálculo de la diferencia de tiempo entre la generación de pedido y el momento actual.
   const difference = moment.duration(moment.utc(new Date()).diff(moment.utc(order.created_at)));
   const diffInHours = difference.asHours();

   return {
      id: order.id,
      userId: order.usuario_id,
      userName: order.neu_usuario.nombre,
      userRut: order.neu_usuario.rut,
      userEmail: order.neu_usuario.email,
      userPhone: order.neu_usuario.fono,
      status: {
         text: order.neu_estatus_pedido.nombre,
         step: order.neu_estatus_pedido.paso
      },
      paymentStatus: order.estado_pago,
      total: order.monto_total_pedido,
      date: moment(order.created_at).format('DD/MM/YYYY'),
      products: order.neu_linea_pedidos.map(pr => {

         const { brand, images, sku } = formatResult(pr.neu_producto);

         return {
            quantity: Number(pr.cantidad_comprada),
            name: pr.neu_producto.nombre,
            sku,
            skuOriginal: pr.neu_producto.sku,
            id: pr.producto_id,
            price: pr.precio_unidad,
            dispatch: pr.recargo,
            installation: pr.precio_instalacion,
            subtotal: Number(pr.precio_total) * Number(pr.cantidad_comprada),
            brand,
            image: images[0]
         }
      }),
      paymentMethodId: order.metodo_pago_id,
      productsQuantity: order.total_productos,
      paymentMethod: {
         id: order?.neu_metodo_pago?.id ?? null,
         name: order?.neu_metodo_pago?.nombre ?? 'Sin especificar',
         image: order?.neu_metodo_pago?.imagen ?? null,
      },
      showPaymentButton: diffInHours < 48,
      billingMethod: order.neu_tipo_facturacion.nombre,
      billing: {
         commune: order.neu_datos_factura_usuario?.neu_comuna.nombre,
         address: order.neu_datos_factura_usuario?.empresa_direccion,
         giro: order.neu_datos_factura_usuario?.empresa_giro,
         razonSocial: order.neu_datos_factura_usuario?.empresa_razon_social,
         rut: order.neu_datos_factura_usuario?.empresa_rut
      },
      region: order.neu_punto_entrega.comuna.neu_region.nombre,
      commune: order.neu_punto_entrega.comuna.nombre.toLocaleLowerCase(),
      place: {
         name: `${order.neu_punto_entrega.nombre} - ${order.neu_punto_entrega.descripcion}`,
         address: order.direccion_entrega
      },
      comments: order.observaciones,
      paymentList: order?.pagos_pedido ?? []
   }
}